import React, { useState, useCallback, useEffect } from "react";
import styles from "./Home.module.scss";

import DefaultLayout from "../../components/defaultLayout/DefaultLayout";

import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core";
import { SCHOOLS_BASEBALL, SCHOOLS_SOFTBALL } from "../../config/Schools";
import ISchool from "../../interfaces/School";
import Button from "../../components/button/Button";
import Header from "../../components/header/Header";
import SearchInput from "../../components/searchInput/SearchInput";
import Pill from "../../components/pill/Pill";
import { useNavigate } from "react-router-dom";
import Requests from "../../utils/Requests";

import bg from "../../assets/dc-bg.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    "&& .MuiDataGrid-root": {
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 400,
      backgroundColor: "#f4f5f7",
    },
    "&& .MuiDataGrid-cell": {
      width: "fit-content",
    },
    "& .centered-cell": {
      textAlign: "center",
    },
    "&& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "&& .error": {
      backgroundColor: "#ed6663",
      color: "white",
    },
  },
}));

const CURRENT_SEASON = 2024;

const Home: React.FC<any> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const [schools, setSchools] = useState(SCHOOLS_BASEBALL);
  const [isBaseball, setIsBaseball] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [disclaimer, setDisclaimer] = useState(true);

  const checkForValidToken = useCallback(async () => {
    const loginToken = localStorage.getItem("login-token");
    try {
      const params = { query: { token: loginToken } };
      await Requests.get("/token/get_one", null, { params });
    } catch {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    checkForValidToken();
  }, [checkForValidToken]);

  const downloadReport = useCallback((year: string, filename: string) => {
    const bucket = "https://s3.amazonaws.com/charts.diamondchartsllc.com";
    const link = document.createElement("a");
    link.href = `${bucket}/${year}/${filename}?t=${Date.now()}`;
    document.body.appendChild(link);
    link.click();
    link.parentNode ? link.parentNode.removeChild(link) : console.log();
  }, []);

  const renderPreviousCell = useCallback(
    (row: ISchool, year: number) => {
      return (
        <div className={styles.cellContainer}>
          <Button
            text="H"
            onClick={() => downloadReport(year.toString(), row.encoded_h)}
            className={styles.greenButton}
          />
          <Button
            text="P"
            onClick={() => downloadReport(`${year}_p`, row.encoded_p)}
            className={styles.blackButton}
          />
        </div>
      );
    },
    [downloadReport]
  );

  const renderCurrentCell = useCallback(
    (row: ISchool, year: number) => {
      return (
        <div className={styles.cellContainer}>
          <Button
            text="H"
            onClick={() => downloadReport(year.toString(), row.encoded_h)}
            className={styles.greenButton}
          />
          <Button
            text="Q"
            onClick={() => downloadReport(year.toString(), row.encoded_q)}
            className={styles.greenButton}
          />
          <Button
            text="CAREER"
            onClick={() => downloadReport(year.toString(), row.encoded_c)}
            className={styles.greenButton}
          />
          <Button
            text="P"
            onClick={() => downloadReport(`${year}_p`, row.encoded_p)}
            className={styles.blackButton}
          />
        </div>
      );
    },
    [downloadReport]
  );

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "School",
      headerAlign: "center",
      headerClassName: styles.columnHeader,
      sortable: false,
      width: 200,
    },
    {
      field: "division",
      headerName: "Division",
      headerAlign: "center",
      headerClassName: styles.columnHeader,
      cellClassName: "centered-cell",
      sortable: false,
      width: 100,
    },
    {
      field: "previousYear2020",
      headerName: (CURRENT_SEASON - 3).toString(),
      headerAlign: "center",
      headerClassName: styles.columnHeader,
      sortable: false,
      width: 100,
      renderCell: (params: any) =>
        renderPreviousCell(params.row, CURRENT_SEASON - 3),
    },
    {
      field: "previousYear2021",
      headerName: (CURRENT_SEASON - 2).toString(),
      headerAlign: "center",
      headerClassName: styles.columnHeader,
      sortable: false,
      width: 100,
      renderCell: (params: any) =>
        renderPreviousCell(params.row, CURRENT_SEASON - 2),
    },
    {
      field: "previousYear2022",
      headerName: (CURRENT_SEASON - 1).toString(),
      headerAlign: "center",
      headerClassName: styles.columnHeader,
      sortable: false,
      width: 100,
      renderCell: (params: any) =>
        renderPreviousCell(params.row, CURRENT_SEASON - 1),
    },
    {
      field: "currentYear",
      headerName: CURRENT_SEASON.toString(),
      headerAlign: "center",
      headerClassName: styles.columnHeader,
      sortable: false,
      width: 220,
      renderCell: (params: any) =>
        renderCurrentCell(params.row, CURRENT_SEASON),
    },
  ];

  const filterSchools = useCallback(
    (search: string) => {
      let matchingSchools = isBaseball ? SCHOOLS_BASEBALL : SCHOOLS_SOFTBALL;
      matchingSchools = matchingSchools.filter((school) =>
        school.name.toLowerCase().includes(search.toLowerCase())
      );
      setSchools(matchingSchools);
    },
    [isBaseball, setSchools]
  );

  const handleLogOut = useCallback(() => {
    localStorage.removeItem("login-token");
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    filterSchools(searchText);
  }, [isBaseball, searchText, filterSchools]);

  return (
    <DefaultLayout>
      <div
        className={styles.container}
        style={{
          background: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div>
          <Button
            text="Logout"
            className={styles.greenButton}
            style={{ position: "absolute", top: "20px", right: "20px" }}
            onClick={() => handleLogOut()}
          />
        </div>
        {disclaimer && (
          <div
            style={{
              position: "absolute",
              top: 0,
              width: "100dvw",
              padding: "30px",
              backgroundColor: "#2f9546",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontFamily: "Roboto",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
              flexDirection: "column",
            }}
          >
            <span style={{ marginBottom: 10 }}>
              Notice: We apologize but we will no longer be providing charts for
              the 2025 season and onward. Thank you for all the support.
            </span>
            <Button text="Got it" onClick={() => setDisclaimer(false)} />
          </div>
        )}
        <div className={styles.mainContainer}>
          <Header />
          <div className={styles.bodyContainer}>
            <div className={styles.filtersContainer}>
              <SearchInput
                className={styles.searchBar}
                value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)}
              />
              <div>
                <Pill
                  pills={[
                    {
                      value: "Baseball",
                      onClick: () => setIsBaseball(true),
                      active: isBaseball,
                    },
                    {
                      value: "Softball",
                      onClick: () => setIsBaseball(false),
                      active: !isBaseball,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={classes.root}>
              <DataGrid
                getRowId={(r) => r.org_id}
                rows={schools}
                columns={columns}
                pageSize={17}
                autoHeight
                density="compact"
                disableSelectionOnClick
                disableColumnMenu
              />
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Home;
